import classes from "@/components/form/forms.module.css";
import { useScreenBreakpoints } from "@/contexts/ScreenBreakpointsProvider";
import {
  __InputStylesNames,
  CloseButton,
  InputBaseProps,
  InputVariant,
  StylesApiProps,
  TextInput,
  TextInputProps,
} from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import debounce from "lodash/debounce";
import get from "lodash/get";
import React from "react";
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UseFormReturn,
} from "react-hook-form";

function CustomInputField<T extends FieldValues>({
  hform,
  name,
  rules,
  inputStyles,
  placeholder,
  debouncedSearchText,
  setDebouncedSearchText,
  ...props
}: {
  hform: UseFormReturn<T>;
  debouncedSearchText?: string;
  setDebouncedSearchText?: React.Dispatch<React.SetStateAction<string>>;
  name: Path<T>;
  placeholder?: string;
  rules?: RegisterOptions;
  inputStyles?: StylesApiProps<{
    props: InputBaseProps;
    defaultRef: HTMLInputElement;
    defaultComponent: "input";
    stylesNames: __InputStylesNames;
    variant: InputVariant;
  }>;
} & TextInputProps) {
  const {
    control,
    formState: { errors },
    setValue,
  } = hform;
  const { isMobile, isTablet } = useScreenBreakpoints();
  const error = get(errors, name);
  const { scrollIntoView, targetRef: scrollTargetRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 10,
      duration: 300,
      easing: (x) => {
        return x;
      },
    });

  const debouncedChangeHandler = debounce(function () {
    if (setDebouncedSearchText) {
      setDebouncedSearchText(hform.getValues(name));
    }
  }, 300);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules as RegisterOptions<T, Path<T>>}
      render={({
        field: { onChange, onBlur, value: fieldValue, name, ref },
      }) => (
        <TextInput
          ref={(inputRef) => {
            ref(inputRef);
            if (inputRef) scrollTargetRef.current = inputRef;
          }}
          name={name}
          onChange={(e) => {
            onChange(e);
            if (setDebouncedSearchText) {
              scrollIntoView();
              debouncedChangeHandler.cancel();
              debouncedChangeHandler();
            }
          }}
          onBlur={onBlur}
          value={fieldValue}
          placeholder={placeholder}
          classNames={{ input: classes.inputField }}
          styles={{
            label: {
              color: "#4E4E4E",
              fontWeight: 600,
              fontSize: 18,
              paddingBottom: 10,
              fontFamily: "Vesper Libre",
            },
            input: {
              color: "black",
              background: "white",
              borderRadius: 100,
              fontSize: isMobile || isTablet ? 14 : 16,
              height: 50,
              paddingInline: 20,
              fontFamily: "Inter",
              border: "0.5px solid #000",
            },
            section: { zIndex: 10 },
            error: {
              fontSize: 12,
              fontFamily: "Inter",
              paddingLeft: 10,
              paddingTop: 5,
            },
            ...inputStyles?.styles,
          }}
          error={error && error.message ? String(error.message) : ""}
          {...props}
          {...(setDebouncedSearchText
            ? {
                rightSection:
                  fieldValue.length === 0 ? (
                    props.rightSection
                  ) : (
                    <CloseButton
                      c="white"
                      variant="transparent"
                      onClick={() => {
                        setValue(name, "" as PathValue<T, typeof name>);
                        setDebouncedSearchText("");
                      }}
                    />
                  ),
              }
            : {})}
        />
      )}
    />
  );
}

export default CustomInputField;
