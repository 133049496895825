import imageUrlBuilder from "@sanity/image-url";
import { createClient } from "@sanity/client";

import { ImageLoaderProps } from "next/image";
import { sanityProject } from "contract/common";

export const client = createClient({
  projectId: sanityProject.projectId,
  dataset: sanityProject.dataset,
  apiVersion: "2024-11-06",
  useCdn: true,
});

const builder = imageUrlBuilder(client);

const urls = [
  "https://uthsav-themes-stage.s3.ap-south-1.amazonaws.com",
  "https://uthsav-themes-prod.s3.ap-south-1.amazonaws.com",
];

const sanityImageUrl = (src: string, width: number, quality: number) => {
  return builder.image(src).width(width).quality(quality).auto("format").url();
};

const imageKitLoader = ({ src, width, quality }: ImageLoaderProps) => {
  if (src.includes("cdn.sanity.io")) {
    return sanityImageUrl(src, width, quality || 100);
  }

  const urlToReplace = urls.find((url) => src.includes(url));

  if (!urlToReplace) return src;

  if (src.startsWith("/")) src = src.slice(1);

  const params = [`w-${width}`];
  if (quality) {
    params.push(`q-${quality}`);
  }
  const paramsString = params.join(",");

  let urlEndpoint = "https://ik.imagekit.io/jzq3bhqlc";
  if (urlEndpoint.endsWith("/")) {
    urlEndpoint = urlEndpoint.slice(0, -1);
  }

  src = src.replace(urlToReplace, "");
  return `${urlEndpoint}/${src}?tr=${paramsString}`;
};

export default imageKitLoader;

// export default function customImageLoader({
//   src,
//   width,
//   quality,
// }: {
//   src: string;
//   width: number;
//   quality: number;
// }) {
//   return `https://image-compress.uthsav.com/_next/image?url=${src}&w=${width}&q=${
//     quality || 75
//   }`;
// }
